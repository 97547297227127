import React from 'react';
import Carousel from 'nuka-carousel';

export default class RoomsSlider extends React.Component {
  render() {
		const carouselConfig = {
			heightMode: 'max',
			autoplay: this.props.autoplay ? this.props.autoplay : false,
			autoplayInterval: 1500,
			enableKeyboardControls: true,
			dragging: true,
			transitionMode: 'fade',
			wrapAround: true,
			// renderAnnounceSlideMessage: ({ currentSlide, slideCount }) => `Slide ${currentSlide + 1} of ${slideCount}`,
			// renderBottomCenterControls: ({ currentSlide }) => (null),
    }
    const RollList = this.props.RollList;
    let moncarousel = RollList.map((roll) => {
      return (
          <img src={ roll.img_src } key={roll.id} onLoad={this._handleImageLoaded} />
      );
    });
    return (
      <Carousel {...carouselConfig}>
        {moncarousel}
      </Carousel>
    );
  }
}